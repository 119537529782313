import React, { useState, useEffect } from 'react';
import './AdminUserPage.css';
import MirielHeader from './MirielHeader';
import MirielFetch from './MirielFetch';

import useRedirectIfNoToken from './UseRedirect';

function AdminUserPage() {
  const [serverMessage, setServerMessage] = useState('')
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState('');
  const [debugLog, setDebugLog] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [currentUser, setCurrentUser] = useState(undefined);
  const [userCollections, setUserCollections] = useState([]);
  const [userList, setUserList] = useState([]);

  const fetcher = new MirielFetch();

  useRedirectIfNoToken();


  useEffect(()=>{
    getUserCollections();
  },[currentUser])

  const createUser = async (event) => {

    event.preventDefault();
    fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/create_user', "POST", 
      {username: username, password: password})
    .then( (response) => {
      console.log("create user successful", response.data);
      setServerMessage('User successfully created:' + username);
      getallusers(event);
      setSelectedUser(username)
      setUserCollections([]);
      setCurrentUser(username);
    })
    .catch (error => {          
      console.error("create failed", error);
      setServerMessage('Error Creating User:'+error);
    });
  };

  const addcollectionperm = async (event) => {
    event.preventDefault();
    fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/add_collection_permission', "POST", 
      {username: currentUser, collection: selectedCollection})
    .then( (response) => {
      console.log("create user permission successful", response.data);
      setServerMessage('User Permission successfully created:' + currentUser +' To:'+selectedCollection);
      getUserCollections();
    })
    .catch (error => {          
      console.error("create permission failed", error);
      setServerMessage('Error Creating User Persmission:'+error);
    });
  };

  const getallusers = async (event) => {
    event.preventDefault();
    fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/get_users', "GET", 
      undefined)
    .then( (response) => {
      console.log("get user successful", response.data);
      setUserList(response.data.users)
      setSelectedUser(response.data.users[0]);
    })
    .catch (error => {          
      console.error("get users failed", error);
      setServerMessage('Error getting all users:'+error);
    });
  };

  const deleteuser = async (event) => {
    event.preventDefault();
    let isConfirmed = window.confirm('Are you sure you want to delete:' + selectedUser)
    if (!isConfirmed) {
      return;
    }
    fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/delete_user', "POST", 
    {username: currentUser})
    .then( (response) => {
      console.log("delete user successful", response.data);
      setUserList([]);
      setCurrentUser(undefined);
      setUserCollections([]);
    })
    .catch (error => {          
      console.error("get users failed", error);
      setServerMessage('Error getting all users:'+error);
    });
  }
  const getcollections = async (event) => {
    event.preventDefault();
    fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/get_collections', "POST", 
      {username: "*"})
    .then( (response) => {
      console.log("create user successful", response.data);
      setCollections(response.data.collections)
      setSelectedCollection(response.data.collections[0]);
    })
    .catch (error => {          
      console.error("get collections failed", error);
      setServerMessage('Error Getting Collections:'+error);
    });
  };

  const getDebugLog = async (event) => {
    event.preventDefault();
    fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/log', "POST", 
      {level: 'DEBUG'})
    .then( (response) => {
      console.log("GetDebugLog", response.data);
      setDebugLog(response.data['miriel.log']);
    })
    .catch (error => {          
      console.error("get debug log failed", error);
      setDebugLog('Error Getting debug log:'+error);
    });
  };


  const getUserCollections = async () => {
    if (currentUser == undefined) {
      return;
    }
    fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/get_collections', "POST", 
      {username: currentUser})
    .then( (response) => {
      console.log("get collections successful", response.data);
      setUserCollections(response.data.collections);
    })
    .catch (error => {          
      console.error("get user collections failed", error);
      setServerMessage('Error getting User collections:'+error);
    });
  };

  const changePassword = async (event) => {
    event.preventDefault();
    fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/change_password', "POST", 
      {username: currentUser, password: password})
    .then( (response) => {
      console.log("password change successful", response.data);
      setServerMessage('User password changed successfully:' + currentUser);
    })
    .catch (error => {          
      console.error("change password failed", error);
      setServerMessage('Error Changing User Password:'+error);
    });
  };

  const selectUser = async (event) => {
    event.preventDefault();
    console.log("setting current user");
    setUserCollections([]);
    setCurrentUser(selectedUser);
  }

  // Handle selection change
  const handleSelectCollectionChange = (event) => {
    console.log("setting collection");
    setSelectedCollection(event.target.value);
  };

  // Handle selection change
  const handleSelectUserChange = (event) => {
    console.log("setting collection");
    console.log("selected user is: "+ event);

    setSelectedUser(event.target.value);
  };

  return (
    <div>
      <MirielHeader />
      <h1>{serverMessage}</h1>
      <div className="entry-container">
        <h2>Create User</h2>
        <form onSubmit={createUser} className="login-form">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Create User</button>
        </form>
      </div>
      <h1>Current User: {currentUser}</h1>
      <div style={{ "flexDirection": "row"}} className="entry-container">
        {selectedUser && <select value={selectedUser} onChange={handleSelectUserChange}>
              {userList.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
        </select>}
        <button type="submit" onClick={getallusers}>Refresh Users</button>
        {selectedUser &&  <button type="submit" onClick={selectUser}>Select User</button> }
      </div>
      {currentUser && (
        <div>
          <div className="entry-container">
            <h2>User Collections</h2>
            <div>
              {userCollections.map((item, index) => (
                <div key={index}>
                  {item}
                </div>
              ))}
            </div>
          </div>
        <div className="entry-container">
          <h2>Change User Password</h2>
          <form onSubmit={changePassword} className="login-form">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type="submit">Change Password</button>
          </form>
        </div>
        <div className="entry-container">
          <h2>Add Collection Permission</h2>
          <button type="submit" onClick={getcollections}>Refresh Collections</button>
          <form onSubmit={addcollectionperm} className="login-form">
            <select value={selectedCollection} onChange={handleSelectCollectionChange}>
              {collections.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
            <button type="submit">Grant Permission</button>
          </form>
        </div>
        <div className="entry-container">
          <h2>Get Debug Log</h2>
          <button type="submit" onClick={getDebugLog}>Refresh Debug Log</button>
          <div style={{ width: '600px', height: '300px', overflowY: 'scroll' }}>
            {debugLog.map((value, index) => (
              <div key={index}>
                {`${value}`}
              </div>
            ))}
          </div>
        </div>
        <div className="entry-container">
          <h2>Delete User</h2>
          <button type="submit" onClick={deleteuser}>DELETE USER</button>
        </div>
      </div>
      )}
    </div>
  );
}

export default AdminUserPage;