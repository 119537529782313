import React, { useEffect } from 'react';
import { useState } from 'react';
import MirielFetch from './MirielFetch';
import './GooglePane.css';
import {DNA} from 'react-loader-spinner';
import { Button } from 'react-bootstrap';

const GooglePane = ( { email }) => {
    const [isGettingGoogleDriveFolders, setIsGettingGoogleDriveFolders] = useState(true); 
    const [googleDriveFolders, setGoogleDriveFolders] = useState([]);
    const [googleDriveDrives, setGoogleDriveDrives] = useState([]);
    const [checkedFolders, setCheckedFolders] = useState([]);
    const [driveContents, setDriveContents] = useState({});
    const [isAddingPaths, setIsAddingPaths] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [monitorSources, setMonitorSources] = useState([]);
    console.log("GooglePane email", email); 

    const addDrivePaths = () => {
        let urls_to_add = [];
        setSuccessMessage('');
        for (const folder of checkedFolders) {
            urls_to_add.push({url: `gdrive://${email}/${folder}`});
        }
        setIsAddingPaths(true);
        const fetcher = new MirielFetch();
        fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/add_urls', "POST",
        {"urls": urls_to_add, discoverable: false})
        .then( (response) => {
            console.log(response);
            setIsAddingPaths(false);
            setCheckedFolders([]);
            setSuccessMessage("Drive paths added to miriel");
        })
        .catch( error => {
            console.error('Error:', error);
            setIsAddingPaths(false);
            setSuccessMessage("Failed to add drive paths to miriel");
        });
    };
    

    const getMonitorSources = () => {
        const fetcher = new MirielFetch();
        fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/get_monitor_sources', "GET",
        undefined)
        .then( (response) => {
            console.log(response);
            setMonitorSources(response.data.sources);
        })
        .catch( error => {
            console.error('Error:', error);
        });
    };

    const handleFolderCheck = (folderId) => {
        if (checkedFolders.includes(folderId)) {
            setCheckedFolders(checkedFolders.filter(id => id !== folderId));
        } else {
            setCheckedFolders([...checkedFolders, folderId]);
        }
    };

    const getGoogleDriveContents = (driveId) => {
        const fetcher = new MirielFetch();
        setDriveContents(prevState => ({
            ...prevState,
            [driveId]: ["LOADING"]
        }));
        fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/get_google_drive_folder_contents', "POST",
        {"drive_id": driveId, "email": email})
        .then( (response) => {
            console.log(response);
            //driveContents[driveId] = response.data.drive;
            console.log(driveContents);
            console.log( "setting drive contents")
            setDriveContents(prevState => ({
                ...prevState,
                [driveId]: response.data.drive
            }));
        })
        .catch( error => {
            console.error('Error:', error);
        });
    }

    const getGoogleDriveFolders = () => {
        const fetcher = new MirielFetch();
        fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/get_google_drive_folders', "POST", 
        {"email": email})
        .then( (response) => {
            setIsGettingGoogleDriveFolders(false);
            setGoogleDriveFolders(response.data.drive);
            setGoogleDriveDrives(response.data.shared_drives);
        })
        .catch (error => {
            console.error('Error:', error);
            setIsGettingGoogleDriveFolders(false);
        });
    }

    
    const isMonitored = (source, type) => {
        if (type == "gmail") {
            for (const monitorSource of monitorSources) {
                //check to see if the monitorSource.source starts with gmail
                if (monitorSource.type == "uri" && monitorSource.source.startsWith("gmail://"+email)) {
                    return true;
                }
                
                
            }
        } else if (type == "googledrive") {
            for (const monitorSource of monitorSources) {
                if (monitorSource.type == "uri" && monitorSource.source == source) {
                    return true;
                }
            }
        } else if (type == "gcalendar") {
            for (const monitorSource of monitorSources) {
                if (monitorSource.type == "uri" && monitorSource.source.startsWith("gcalendar://"+email)) {
                    return true;
                }
            }
        }
        return false
    }

    const syncGmail = () => {
        setIsAddingPaths(true);
        let urls_to_add = [{url: "gmail://"+email}];
        const fetcher = new MirielFetch();
        fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/add_urls', "POST",
        {"urls": urls_to_add, discoverable: false})
        .then( (response) => {
            setIsAddingPaths(false);
            console.log(response);
            setSuccessMessage("Gmail added to miriel");
            setMonitorSources([...monitorSources, {source:  "gmail://"+email, type: "gmail"}]);
        })
        .catch( error => {
            setIsAddingPaths(false);
            console.error('Error:', error);
            setSuccessMessage("Failed to add gmail paths to miriel");
        });
    }

    const syncGCalendar = () => {
        setIsAddingPaths(true);
        let urls_to_add = [{url: "gcalendar://"+email}];
        const fetcher = new MirielFetch();
        fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/add_urls', "POST",
        {"urls": urls_to_add, discoverable: false})
        .then( (response) => {
            setIsAddingPaths(false);
            console.log(response);
            setSuccessMessage("Google Calendar added to miriel");
            setMonitorSources([...monitorSources, {source: "gcalendar://"+email, type: "gcalendar"}]);
        })
        .catch( error => {
            setIsAddingPaths(false);
            console.error('Error:', error);
            setSuccessMessage("Failed to add google calendar paths to miriel");
        });
    }
  
    useEffect(() => {
        getMonitorSources();
        getGoogleDriveFolders();
    }, []);


    const getFolderView = (folder) => {
        if (folder === "LOADING") {
            return (
                <div>
                    <DNA
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    wrapperStyle={{}}
                    wrapperClass="dna-wrapper"
                    />
                </div>
            );
        }
        return (
            <div key={folder.id}>
                <input
                    type="checkbox"
                    checked={checkedFolders.includes(folder.id)}
                    onChange={() => handleFolderCheck(folder.id)}
                />
                {folder.name}
            </div>
        );  
    }
    const username = localStorage.getItem('username');
    return (
        <div>
            <h3>Account</h3>
            {(
                <div>
                    {(isGettingGoogleDriveFolders && (
                                <DNA
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="dna-loading"
                                wrapperStyle={{}}
                                wrapperClass="dna-wrapper"
                                />
                            )
                    )}
                    {(googleDriveFolders.length > 0 && (
                        <div>
                            <h3>Your Drive</h3>
                            <ul>
                                {googleDriveFolders.map((folder) => (
                                    <li key={folder.id}>
                                        <input
                                            type="checkbox"
                                            checked={checkedFolders.includes(folder.id)}
                                            onChange={() => handleFolderCheck(folder.id)}
                                        />
                                        {folder.name}
                                    </li>
                                ))}
                            </ul>
                            <div>
                                <h3>Shared Drives</h3>
                                <ul>
                                    {googleDriveDrives.map((drive) => (
                                        <li key={drive.id}>
                                            <a href="#" onClick={() => getGoogleDriveContents(drive.id)}>{drive.name}</a>
                                            {driveContents[drive.id] && (
                                                driveContents[drive.id].map((folder) => (
                                                    getFolderView(folder)
                                                ))
                                            )}
                                        </li>
                                    ))}
                                </ul>
                                <div style={{display: 'flex', justifyContent:'space-between'}}>
                                    <Button variant="primary" onClick={addDrivePaths} disabled={isAddingPaths}>Add Drive Paths</Button>
                                </div>
                                <hr></hr>
                                {!isMonitored(username, "gmail") && (
                                    <div style={{display: 'flex', justifyContent:'space-between'}}>
                                        <Button variant="primary" onClick={syncGmail} disabled={isAddingPaths}>Add Gmail</Button>
                                    </div>
                                )}
                                {!isMonitored(username, "gcalendar") && (
                                    <div style={{display: 'flex', justifyContent:'space-between'}}>
                                        <Button variant="primary" onClick={syncGCalendar} disabled={isAddingPaths}>Add GCalendar</Button>
                                    </div>
                                )}
                                {isMonitored(username, "gmail") && (
                                    <div>
                                        <div>Gmail Monitored</div>
                                    </div>
                                )}
                                {isMonitored(username, "gcalendar") && (
                                    <div>
                                        <div>Google Calendar Monitored</div>
                                    </div>
                                )}
                                {isAddingPaths && (
                                    <DNA
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="dna-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="dna-wrapper"
                                    />
                                )}
                                {successMessage && (
                                    <div>{successMessage}</div>
                                )}
                            </div>
                        </div>
                    ))} 
                </div>
            )}
            <hr></hr>
        </div>
    );
};

export default GooglePane;
