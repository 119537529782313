import './AccountPage.css';
import React, { useEffect, useState } from 'react';
import MirielHeader from './MirielHeader';
import { Container, Form, Button, Row, Col, Tab, Nav } from 'react-bootstrap';

import GoogleAccountList from './GoogleAccountList';
import FileManagePage from './FileManagePage';
import MirielFetch from './MirielFetch';

function AccountPage() {
  let username = localStorage.getItem('username');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [serverMessage, setServerMessage] = useState('');
  
  const fetcher = new MirielFetch();
  const changePassword = async (event) => {
    event.preventDefault();
    setServerMessage('');
    fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/change_password', "POST", 
      {username: username, password: newPassword, old_password: oldPassword})
    .then( (response) => {
      console.log("password change successful", response.data);
      setServerMessage('User password changed successfully');
    })
    .catch (error => {          
      console.error("change password failed", error);
      setServerMessage('Error Changing User Password:'+error);
    });
  };

  return (
    <Container>
      <MirielHeader />
      <div>{serverMessage}</div>
      <Tab.Container defaultActiveKey="account">
        <Row style={{width: '100%', paddingBottom: 30}}>
          <Col sm={4}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="account">Account</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="password">Change Password</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="googleaccount">Manage Google Account</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fileadd">Add Files</Nav.Link>
              </Nav.Item>
              {/* Add more Nav.Item components for each tab */}
            </Nav>
          </Col>
          <Col sm={8}>
            <Tab.Content>
              <Tab.Pane eventKey="account">
                <h3>Account</h3>
                <Form>
                  <Form.Group as={Row} controlId="formUsername">
                    <Form.Label column sm={4}><b>Username:</b></Form.Label>
                    <Col sm={9}>
                      <Form.Label column sm={8}>{username}</Form.Label>
                    </Col>
                  </Form.Group>
                  {/* Add more Form.Group components for each field */}

                </Form>
              </Tab.Pane>
              <Tab.Pane eventKey="password">
                <h3>Account</h3>
                <Form onSubmit={changePassword}>
                  <Form.Group as={Row} controlId="formUsername">
                    <Col sm={3}>
                      <Form.Label style={{marginTop: 10}}><b>Old Password</b></Form.Label>
                      <Form.Label style={{marginTop: 15}}><b>New Password</b></Form.Label>
                    </Col>
     
                    <Col sm={9}>
                      <Form.Control type="password" placeholder="Current Password"  onChange={(e) => setOldPassword(e.target.value)} />
                      <Form.Control type="password" placeholder="New Password" style={{marginTop: 10, marginBottom: 10}}  onChange={(e) => setNewPassword(e.target.value)}/>
                    </Col>
                  </Form.Group>
                  {/* Add more Form.Group components for each field */}
                  <Button variant="primary" type="submit">Update Password</Button>
                </Form>
              </Tab.Pane>
              <Tab.Pane eventKey="googleaccount">
                <GoogleAccountList />
              </Tab.Pane>
              <Tab.Pane eventKey="fileadd">
                <FileManagePage />
              </Tab.Pane>
              {/* Add more Tab.Pane components for each tab content */}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}


export default AccountPage;
