import React, { useEffect } from 'react';
import { useState } from 'react';
import MirielFetch from './MirielFetch';
import {DNA} from 'react-loader-spinner';
import { Button } from 'react-bootstrap';
import GooglePane from './GooglePane';

const GoogleAccountList = () => {
    const [isGettingLoggedIn, setIsGettingLoggedIn] = useState(true);
    const [accounts, setAccounts] = useState([]);

    const handleLogin = () => {
        const token = localStorage.getItem('userToken');
        // Redirect to your backend endpoint to initiate OAuth flow
        window.location.href = process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/auth/google?token='+token;
    };

    const handleUnLink = (email) => {
        const fetcher = new MirielFetch();
        fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/deauth/google', "POST", 
        {email: email})
        .then( (response) => {
            if (response.data.status === 'success') {
                getLoggedInGoogleAccounts();
            }
        })
        .catch (error => {
            console.error('Error:', error);
        });
    }

    const getLoggedInGoogleAccounts = () => {
        const fetcher = new MirielFetch();
        fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/get_google_logged_in_accounts', "GET", 
        undefined)
        .then( (response) => {
            setIsGettingLoggedIn(false);
            if (response.data.logged_in_accounts) {
                setAccounts(response.data.logged_in_accounts);
            }
        })
        .catch (error => {
            console.error('Error:', error);
            setIsGettingLoggedIn(false);
        });
    }

    useEffect(() => {
        getLoggedInGoogleAccounts();
    }, []);
    return (
        <div>
            {isGettingLoggedIn && (
                <DNA
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
                />
            )}
            {accounts.map((item, index) => (
                <div>
                    <div>
                        <div>{item.email}</div>
                        <div><Button variant="primary" onClick={()=>handleUnLink(item.email)}>Unlink</Button></div>
                    </div>
                    <GooglePane email={item.email} key={index} />
                </div>
            ))}
            <Button variant="primary" onClick={handleLogin}>Link an Account</Button>
        </div>
    );
}

export default GoogleAccountList;