import React from 'react';
import './AboutPage.css'; // Importing the CSS file
import MirielHeader from './MirielHeader';

function AboutPage() {
  return (
    <div className="about-container">
      <MirielHeader />
      <img className="headerimage" src="header.png" alt="header image"/>
      <h1 className='about'>About Miriel.ai</h1>
      <div className='companyblurb'>
        <p className='companyblurbtext'>Even if humans use 100% of their brain, they definitely don't use 100% of their data. We no longer remember phone numbers and never figured out where our keys are. So how are you going to figure out the exact color and brand of the paint you used while renovating the kitchen 10 years ago?

We're building personalized AI tools with applications across all industries, but our first focus is real estate. We're piloting today with HOAs and property managers to improve owner/resident experiences.</p>
      </div>
      <div className="founders">
        <div className="founder">
          <img src="andy.png" alt="Andy Barkett" />
          <h2><a href="https://www.linkedin.com/in/andrew-barkett-9147473/" target="_blank" rel="noopener noreferrer">Andy Barkett</a></h2>
          <p>Co-Founder</p>
        </div>
        <div className="founder">
          <img src="josh.png" alt="Josh Paulson" />
          <h2><a href="https://www.linkedin.com/in/joshuapaulson/" target="_blank" rel="noopener noreferrer">Josh Paulson</a></h2>
          <p>Co-Founder</p>
        </div>
        <div className="founder">
          <img src="david.png" alt="David Garcia" />
          <h2><a href="https://www.linkedin.com/in/david-h-garcia/" target="_blank" rel="noopener noreferrer">David Garcia</a></h2>
          <p>Co-Founder</p>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
