import React, { useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import MirielFetch from './MirielFetch';
import Button from 'react-bootstrap/Button';

const ApprovalTextArea = ({ content, uuid, callback }) => {

    const approveDocument = async (uuid) => {
        console.log("Approving Document with UUID: ", uuid);
        const postData = {
          'uuid': uuid,
          'approved_partial_document':  content == value ? true : false,
          'approved_full_document': false,
          'alternate_answer': content == value ? undefined : value,
          'completed' : true
        };
        const fetcher = new MirielFetch();
        fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/resolve_approval_request', "POST", 
          postData)
          .then( (response) => {
            console.log("Got a response", response.data.requests);
            callback();
          })
          .catch (error => {
            console.error('Error:', error);
          });
      };
      
      const approveResource = async (uuid) => {
      
        const fetcher = new MirielFetch();
        const postData = {
          'uuid': uuid,
          'approved_partial_document': false,
          'approved_full_document': true,
          'alternate_answer': undefined,
          'completed' : true
        };
        fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/resolve_approval_request', "POST", 
          postData)
          .then( (response) => {
            console.log("Got a response", response.data.requests);
          })
          .catch (error => {
            console.error('Error:', error);
          });
      };
      
      const denyRequest = async (uuid) => {
      
        const fetcher = new MirielFetch();
        const postData = {
          'uuid': uuid,
          'approved_partial_document':  false,
          'approved_full_document': false,
          'alternate_answer': undefined,
          'completed' : true
        };
        fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/resolve_approval_request', "POST", 
          postData)
          .then( (response) => {
            console.log("Got a response", response.data.requests);
          })
          .catch (error => {
            console.error('Error:', error);
          });
      };

    // State to manage the number of rows for the textarea
    const [rows, setRows] = useState(2);
    const [value, setValue] = useState(content);

    // Function to expand the textarea
    const handleFocus = () => {
        setRows(10); // Adjust the number to suit your needs
    };

    // Function to shrink the textarea back to 2 lines
    const handleBlur = () => {
        setRows(2);
    };

    return (
        <div>
            <textarea style={{width: '100%', resize: 'none'}}
                rows={rows}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e) => setValue(e.target.value)}
                value={value}
            ></textarea>
            {value != content && <div><Badge bg="success">Modified</Badge></div>}
            <div className='approvalButtonGroup'>
                          <Button className="approvalButton" onClick={()=>approveDocument(uuid)} variant="outline-primary">{content == value ? "Approve Section" : "Approve Modified Section"}</Button>
                          <Button className="approvalButton" onClick={()=>approveResource(uuid)} variant="outline-success">Approve Full Document</Button>
                          <Button className="approvalButton" onClick={()=>denyRequest(uuid)}variant="outline-danger">Deny</Button>
            </div>
        </div>
    );
};

export default ApprovalTextArea;