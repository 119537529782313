import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function useRedirectIfNoToken() {
  const navigate = useNavigate();

  useEffect(() => {
    // Check for the token in local storage
    const token = localStorage.getItem('userToken');

    // If the token doesn't exist, redirect to "/"
    if (!token) {
      navigate('/');
    }
  }, [navigate]); // Dependency array includes navigate to avoid unnecessary re-renders
}

export default useRedirectIfNoToken;
