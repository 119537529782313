import MirielHeader from './MirielHeader';
import './ApprovalsPage.css';

import React, { useEffect, useState } from 'react';

import {DNA} from 'react-loader-spinner';
import MirielFetch from './MirielFetch';

import useRedirectIfNoToken from './UseRedirect';
import { testApprovalRequestsData, testApprovalsData } from './TestData';
import Accordion from 'react-bootstrap/Accordion';
import { RxReload } from "react-icons/rx";
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import ApprovalTextArea from './ApprovalTextArea';
import { FaFilePdf } from "react-icons/fa6";
import { FaFileImage } from "react-icons/fa";
import { FaFileVideo } from "react-icons/fa";
import { FaRegFileAudio } from "react-icons/fa";
import { LuFileText } from "react-icons/lu";
import { BsFileEarmarkSlides } from "react-icons/bs";
import { LuFileJson2 } from "react-icons/lu";
import { BsFiletypeXml } from "react-icons/bs";
import { BsFiletypeHtml } from "react-icons/bs";
import { FaRegFileZipper } from "react-icons/fa6";
import { TbFileSpreadsheet } from "react-icons/tb";


const getFilePath = (source) => {
  //source is the file path, return an icon based on the file type
  let lastSlashIndex = source.lastIndexOf('/');
  let fileName = source.substring(lastSlashIndex + 1);
  return fileName;
};

const getFileExtension = (source) => { 
  //source is the file path, return an icon based on the file type
  let lastDotIndex = source.lastIndexOf('.');
  let extension = source.substring(lastDotIndex);
  return extension;
}

//function to get only the filename given a path
const getFileName = (source) => {
  if (source == undefined) return ("Document Name Hidden");
  let lastSlashIndex = source.lastIndexOf('/');
  let fileName = source.substring(lastSlashIndex + 1);
  return fileName;
};

const ApprovalList = ({ data }) => {
  
  // Change it to only show 5 chunks of answers
  // Add downvoting to chunk/remove
  
  return (
      <Accordion className="resultList">
          {data.map((item, index) => (
              <Accordion.Item key={index} eventKey={index}>
                  <Accordion.Header>{item.query}</Accordion.Header>
                  <Accordion.Body>
                  <ListGroup as="ol">
                    { item.requests.map((item, index) => (
                      <ListGroup.Item as="li">
                        <p>{getFileName(item.resource)}</p>
                        <div style={{display:'flex', flexDirection:'row'}}>
                          <p>{item.owner ? item.owner : "Hidden"}</p><div>  </div>
                          { item.status =='pending' && <p><Badge bg="secondary">Pending</Badge></p> }
                          { item.status =='approved' && <p><Badge bg="success">Approved</Badge></p> }
                        </div>
                      </ListGroup.Item>
                    ))}
                    </ListGroup>
                  </Accordion.Body>
              </Accordion.Item>
          ))}
    </Accordion>
  );
};



const ApprovalRequestList = ({ data, callback }) => {


  const getIcon = (source,) => {
    //source is the file path, return an icon based on the file type
    const type = getFileExtension(source);
    switch (type) {
        case '.eml': return <LuFileText />;
        case '.msg': return <LuFileText />;
        case '.docx': return <LuFileText />;
        case '.pdf': return <FaFilePdf />;
        case '.xlsx': return <TbFileSpreadsheet />  ;
        case '.pptx': return <BsFileEarmarkSlides />;
        case '.txt': return <LuFileText />;
        case '.csv': return <LuFileText />;
        case '.json': return <LuFileJson2 />;
        case '.xml': return <BsFiletypeXml />;
        case '.html': return <BsFiletypeHtml />;
        case '.zip': return <FaRegFileZipper />;
        case '.jpg': return <FaFileImage />;
        case '.png': return <FaFileImage />;
        case '.gif': return <FaFileImage />;
        case '.mp4': return <FaFileVideo />;
        case '.mp3': return <FaRegFileAudio />;
        case '.wav': return <FaRegFileAudio />;

    }
  };
  
  // Change it to only show 5 chunks of answers
  // Add downvoting to chunk/remove
  
  return (
      <Accordion className="resultList">
          {data.map((item, index) => (
              <Accordion.Item key={index} eventKey={index}>
                  <Accordion.Header>{item.requester} is asking "{item.query}" </Accordion.Header>
                  <Accordion.Body>
                      <p>{getIcon(item.resource)} Requesting access to {getFilePath(item.resource)} </p>
                      <div>Section Requested:</div>
                      <ApprovalTextArea content={item.document} uuid={item.uuid} callback={callback}/>
                  </Accordion.Body>
              </Accordion.Item>
          ))}
    </Accordion>
  );
};


function ApprovalsPage() {
  const [requestData, setRequestData] = useState(null);
  const [approvalData, setApprovalData] = useState(null);
  const [errorText, setErrorText] = useState(undefined);
  const [isLoadingApprovals, setIsLoadingApprovals] = useState(false);
  const [isLoadingRequests, setIsLoadingRequests] = useState(false);
  const token = localStorage.getItem('userToken');
  useRedirectIfNoToken();

  const requestRequests = async () => {
    setErrorText(undefined);
    if (process.env.REACT_APP_APP_USE_FAKE_DATA == "TRUE") {
      setRequestData(testApprovalRequestsData.requests);
      return;
    }
    const fetcher = new MirielFetch();
    fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/get_approval_requests', "GET", 
      undefined)
      .then( (response) => {
        setErrorText(undefined);
        setRequestData(response.data.requests);
        console.log("Got a response", response.data.requests);
      })
      .catch (error => {
        console.error('Error:', error);
        setErrorText("Error Fetching Requests");
      });
  };

  const requestApprovals = async () => {
    setErrorText(undefined);
    if (process.env.REACT_APP_APP_USE_FAKE_DATA == "TRUE") {
      setApprovalData(testApprovalsData.approvals);
      return;
    }
    const fetcher = new MirielFetch();
    fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/get_approvals', "GET", 
      undefined)
      .then( (response) => {
        setErrorText(undefined);
        setApprovalData(response.data.approvals);
        console.log("Got a response", response.data.approvals);
      })
      .catch (error => {
        console.error('Error:', error);
        setErrorText("Error Fetching Approvals");
      });
  }

  useEffect(() => {
    requestApprovals();
    requestRequests();
  }, []);

  return (
    <div>
      <div className="container">
        <MirielHeader />
        <div className="input-container">
            <div className='approvalsHeader'>
              <div className='approvalsTitle'>Your Questions</div>
              <Button onClick={requestApprovals} disabled={isLoadingApprovals} variant="outline-dark"><RxReload /></Button>
            </div>
            {approvalData && (
            <div>
                <ApprovalList data={approvalData} />
            </div>)}
            { approvalData && approvalData.length == 0 && (
              <div>No pending approvals</div>
            )}
            {!approvalData && (
              <DNA
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            )
            }
        </div>
        <div className="input-container">
            <div className='approvalsHeader'>
              <div className='approvalsTitle'>Requests</div>
              <Button variant="outline-dark" onClick={requestRequests} disabled={isLoadingRequests}><RxReload /></Button>
            </div>
            {requestData && (
            <div>
                <ApprovalRequestList data={requestData} callback={()=>requestRequests()} />
            </div>)}
            { requestData && requestData.length == 0 && (
              <div>No pending requests</div>
            )}
            {!requestData && (
              <DNA
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            )
            }
            {
              errorText && (
                <div className="error-search">{errorText}</div>
              )
            }
        </div>
      </div>
    </div>
  );
}

export default ApprovalsPage;
