import React, { useEffect, useState, useRef } from 'react';
import { Graph } from 'react-d3-graph';

const AnimateGraph = ({ relationships }) => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const graphContainerRef = useRef(null);

  useEffect(() => {
    const preventDefaultWheel = (event) => {
      event.preventDefault();
    };

    const graphContainer = graphContainerRef.current;
    if (graphContainer) {
      graphContainer.addEventListener('wheel', preventDefaultWheel);
    }

    return () => {
      if (graphContainer) {
        graphContainer.removeEventListener('wheel', preventDefaultWheel);
      }
    };
  }, []);


  useEffect(() => {
    if (relationships.length > 0) {
      animateGraphCreation(relationships);
    }
  }, [relationships]);

  const animateGraphCreation = (relationships) => {
    let currentNodes = [];
    let currentEdges = [];

    relationships.forEach((item, index) => {
      setTimeout(() => {
        if (!currentNodes.some(node => node.id === item.node1)) {
          currentNodes.push({ id: item.node1, label: item.node1 });
          setNodes([...currentNodes]);
        }
        if (!currentNodes.some(node => node.id === item.node2)) {
          currentNodes.push({ id: item.node2, label: item.node2 });
          setNodes([...currentNodes]);
        }
        currentEdges.push({ source: item.node1, target: item.node2 });
        setEdges([...currentEdges]);
      }, index * 150); // Adjust the timing as needed
    });
  };

  const data = {
    nodes: nodes,
    links: edges
  };

  const config = {
    nodeHighlightBehavior: true,
    node: { color: 'lightgreen', size: 120, highlightStrokeColor: 'blue' },
    link: { highlightColor: 'lightblue' },
    directed: true,
    height: 600,
    width: 800,
    panAndZoom: false // Disable pan and zoom
  };

  return (
    <div ref={graphContainerRef}>
      <Graph
        id="graph-id"
        data={data}
        config={config}
      />
    </div>
  );
};

export default AnimateGraph;