import React, { useState, useEffect } from 'react';

import './FileManagePage.css';
import { Button, Form, ProgressBar, Table } from 'react-bootstrap';
import MirielFetch from './MirielFetch.js';
import MirielHeader from './MirielHeader.js';


const fetcher = new MirielFetch();


const FileManagePage = () => {
    const [files, setFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);

    const getFiles = async () => {
        console.log("Fetching File management data")
        fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL +'/api/v1/get_files', "GET", undefined)
            .then(response => {
                console.log("Got a get files response", response.data);
                setFiles(response.data.files);
            })
            .catch(error => {
                console.error('Failed to fetch management data:', error);
            });
        }

    const addFile = async (event) => {
        event.preventDefault();
        fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/add_file', "POST", 
          {files: selectedFiles, discoverable: true})
        .then( (response) => {
          console.log("create user permission successful", response.data);
        })
        .catch (error => {          
          console.error("create permission failed", error);
        });
      };


    useEffect(() => {
        getFiles();
    }, []);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);

        // Upload file to the server using MirielFetch.post
        fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL +'/api/v1/uploads', "POST", formData, {
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                setUploadProgress(progress);
            }
        })
            .then(response => {
                getFiles();
                setUploadProgress(0);
            })
            .catch(error => {
                console.error('Failed to upload file:', error);
            });
    };

    const handleFileSelect = (event, file) => {
        if (event.target.checked) {
            setSelectedFiles([...selectedFiles, file]);
        } else {
            setSelectedFiles(selectedFiles.filter(selectedFile => selectedFile !== file));
        }
    }
    

    return (
        <div>
            <h1>File Management</h1>

            <Form.Group>
                <Form.Label>Upload File:</Form.Label>
                <Form.Control type="file" onChange={handleFileUpload} />
                {uploadProgress > 0 && <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />}
            </Form.Group>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>File Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {files.map(file => (
                        <tr key={file}>
                            <td>{file}</td>
                            <td>
                                <input type="checkbox" onChange={(event) => handleFileSelect(event, file)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <form onSubmit={addFile} className="login-form">
            <button type="submit">Add File</button>
            </form>
        </div>
    );
};

export default FileManagePage;
