import axios from 'axios';
class MirielFetch {

    onUnauthorized() {
        localStorage.removeItem('userToken');
        localStorage.removeItem('admin');
        window.location.reload();

    }
    getHeaders() {
        // Retrieve the token from localStorage
        const token = localStorage.getItem('userToken');
        const headers = {};

        // Add Authorization header if token exists
        if (token) {
            headers['x-access-token'] = token;
        }
        return headers;
    }

    miriel_fetch(url, method, post_contents) {
        return new Promise((resolve, reject) => {
            const headers = this.getHeaders();

            if (method === 'GET') {
                axios.get(url, { headers })
                    .then(response => resolve(response))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            reject(error);
                        } else {
                            reject(error);
                        }
                    });
            } else if (method === 'POST') {
                axios.post(url, post_contents, { headers })
                    .then(response => resolve(response))
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            this.onUnauthorized();
                            reject(error);
                        } else {
                            reject(error);
                        }
                    });
            } else {
                reject("Incorrect Args");
            }
        });
    }
    
}
    
export default MirielFetch;
    
