import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import MirielHeader from './MirielHeader';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState(undefined);
  const navigate = useNavigate()

  const handleLogin = async (event) => {
    event.preventDefault();
    setErrorText(undefined);
    try {
      const token = btoa(`${username}:${password}`);
      const config = {
        headers: {
            'Authorization': `Basic ${token}`
        }
      };
      axios.post(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/login', {}, config)
      .then(response => {
          console.log("Login successful", response.data);
          localStorage.setItem('userToken', response.data.token);
          localStorage.setItem('admin', response.data.admin);
          localStorage.setItem('username', username);
          navigate('/search');
      })
      .catch(error => {
          setErrorText("Login Failed");
          console.error("Login failed", error);
      });
    } catch (error) {
      console.error(error);
      setErrorText("Login Failed");
    }
  };

  return (
    <div className="login-container">
      <MirielHeader />
      <form onSubmit={handleLogin} className="login-form">
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
        {errorText && 
          <div className="error-login">{errorText}</div>
        }
      </form>
    </div>
  );
}

export default LoginPage;
