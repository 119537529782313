import React, { useState } from 'react';
import './ResultList.css'; // Importing the CSS file
import Accordion from 'react-bootstrap/Accordion';


const ResultList = ({ data }) => {
    const [expandedItem, setExpandedItem] = useState(null);
    const admin = localStorage.getItem('admin');

    const toggleExpand = (index) => {
        setExpandedItem(expandedItem === index ? null : index);
    };

    const getIcon = (type) => {
        switch (type) {
            case 'email': return '📧'; // Replace with actual icons
            case 'document': return '📄';
            case 'discord': return '💬';
            // Add more cases for different types
            default: return '📄';
        }
    };
    
    // Change it to only show 5 chunks of answers
    // Add downvoting to chunk/remove
    const groupedData = data.reduce((acc, item) => {
        const existingItem = acc.find(i => i.source === item.metadata.source);
        if (existingItem) {
            existingItem.documents.push(item);
        } else {
            acc.push({
                metadata: item.metadata,
                source: item.metadata.source,
                documents: [item],
            });
        }
        return acc;
    }, []);
    return (
        <Accordion className="resultList">
            {groupedData.map((item, index) => (
                <Accordion.Item key={index} eventKey={index}>
                    <Accordion.Header> {getIcon(item.metadata.type)} {item.source}</Accordion.Header>
                    <Accordion.Body>
                        {item.documents.map((item, index2) => (
                            <p key={index2}> {item.document} <br></br> {admin == "true" ? "distance:"+item.distance : ''}<hr></hr></p>
                        ))}
                    </Accordion.Body>
                </Accordion.Item>
            ))}
      </Accordion>
    );
};

export default ResultList;
