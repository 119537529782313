import React, {useEffect, useState} from 'react';
import './MirielHeader.css';
import { useNavigate } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import MirielFetch from './MirielFetch';
import { IoMdNotifications } from "react-icons/io";
import { testNotificationsData } from './TestData';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';

function MirielHeader() {
    const token = localStorage.getItem('userToken');
    const admin = localStorage.getItem('admin');
    const username = localStorage.getItem('username');
    const [notifications, setNotifications] = useState([]);
    const navigate = useNavigate()

    const logout = async (event) => {
        event.preventDefault();
        localStorage.removeItem('userToken');
        localStorage.removeItem('admin');
        localStorage.removeItem('username');
        navigate('/');
    }

    const notification_popover = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">Notifications</Popover.Header>
          <Popover.Body>
            {notifications.length == 0 && <p>No notifications!</p>}
            {notifications.map((item, index) => (
                <div key={index}>
                    <p>{item.notification}</p>
                </div>
            ))}
          </Popover.Body>
        </Popover>
      );
    
      const account_popover = (
        <Popover id="popover-basic2">
          <Popover.Header as="h3">{username}</Popover.Header>
          <Popover.Body class={'account'}>
                <Link to="/account">Account</Link> {/* Add a link to the account page */}
                <hr /> {/* Add a line between the links */}
                <a href="/" onClick={logout}>Logout</a> {/* Add a link to logout */}
          </Popover.Body>
        </Popover>
      );
    
    const Notifications = () => (
        <div>
            <OverlayTrigger trigger="click" placement="bottom" overlay={notification_popover} rootClose>
                <Button className="notifs" variant="outline-primary"><IoMdNotifications /> <Badge bg="secondary">{notifications.length}</Badge> </Button>
            </OverlayTrigger>
            <OverlayTrigger trigger="click" placement="bottom" overlay={account_popover} rootClose>
                <Avatar color={'#356efd'} name={username} round size={40} fgColor='white'/>
            </OverlayTrigger>
        </div>
      );



    const fetcher = new MirielFetch();

    const fetchNotifications = async () => {
        if (process.env.REACT_APP_APP_USE_FAKE_DATA == "TRUE") {
            setNotifications(testNotificationsData.notifications);
            return;
          }

        fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/get_notifications', "GET", 
            undefined)
            .then( (response) => {
                setNotifications(response.data.notifications);
                console.log("Got a response", response.data);
            })
            .catch (error => {
                console.error('Error:', error);
                setNotifications([]);
            });
    }

    useEffect(() => {
        if (token) {
            fetchNotifications();
        }
    }, [token]);

    useEffect(() => {
        console.log("Notifications", notifications);
    }, [notifications]);


    return(
        <header className="header">
            <nav className="navbar">
            <ul className="nav-links">
                <li><img className="headerimage" src="smlogo.png" alt="header image"/></li>
                <li><a href="/about">About</a></li>
                {token && <li><a href="/search">Search</a></li>}
                {token && <li><a href="/approvals">Approvals</a></li>}
                {!token && <li><a href="/">Login</a></li>}
                {admin == "true" && <li><a href="/admin">Admin</a></li>}
                {admin == "true" && <li><a href="/adminUser">User Admin</a></li>}
                {token && <Notifications />}
            </ul>
            </nav>
        </header>
    );
}

export default MirielHeader;