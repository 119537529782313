import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './LoginPage';
import AdminPage from './AdminPage';
import SearchPage from './SearchPage';
import AboutPage from './AboutPage';
import AdminUserPage from './AdminUserPage';
import ApprovalsPage from './ApprovalsPage';
import AccountPage from './AccountPage';

function App() {
  return (
    <Router >
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/adminUser" element={<AdminUserPage />} />
        <Route path="/search" element={<SearchPage/>} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/approvals" element={<ApprovalsPage />} />
        <Route path="/account" element={<AccountPage />} />
      </Routes>
    </Router>
  );
}

export default App;
