import logo from './logo.svg';
import MirielHeader from './MirielHeader';
import './SearchPage.css';
import { useNavigate } from 'react-router-dom';

import React, { useState } from 'react';

import {MagnifyingGlass} from 'react-loader-spinner';
import MirielFetch from './MirielFetch';

import useRedirectIfNoToken from './UseRedirect';

import { testSearchData } from './TestData';
import ResultList from './ResultList';
import { IoSendOutline } from "react-icons/io5";
import Button from 'react-bootstrap/esm/Button';

import AnimateGraph from './AnimateGraph';


function SearchPage() {
  const [inputValue, setInputValue] = useState('');
  const [data, setData] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorText, setErrorText] = useState(undefined);
  const [approvalsRequested, setApprovalsRequested] = useState(false);
  const [approvalsRequestComplete, setApprovalsRequestComplete] = useState(false);
  const token = localStorage.getItem('userToken');
  useRedirectIfNoToken();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const requestApprovals = () => {
    setErrorText(undefined);
    setApprovalsRequested(true);
    if (process.env.REACT_APP_APP_USE_FAKE_DATA == "TRUE") {
      console.log("Requesting Approvals");
      setApprovalsRequestComplete(true);
      return;
    }
    const fetcher = new MirielFetch();
    fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/create_approval_requests', "POST",
      { query: inputValue })
      .then( (response) => {
        console.log("Got a response", response.data);
        setErrorText(undefined);
        setApprovalsRequestComplete(true);
      })
      .catch (error => {
        console.error('Error:', error);
        setErrorText("Error Requesting Approvals");
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setData(null);
    setErrorText(undefined);
    setApprovalsRequestComplete(false);
    setApprovalsRequested(false);
    if (process.env.REACT_APP_APP_USE_FAKE_DATA == "TRUE") {
      setIsSubmitted(false);
      setData(testSearchData);
      return;
    }
    const fetcher = new MirielFetch();
    fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/query', "POST", 
      { query: inputValue})
      .then( (response) => {
        setData(response.data);
        console.log("Got a response", response.data);
        setIsSubmitted(false);
      })
      .catch (error => {
        console.error('Error:', error);
        setIsSubmitted(false);
        setErrorText("Error Searching");
      });
    fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/graph_query', "POST",
      { query: inputValue })
      .then( (response) => {
        console.log("Got a response from graph query", response.data);
        setGraphData(response.data.relationships);
      })
    //return;
  };

  return (
    <div>
      <div className="container">
        <MirielHeader />
        <div className='searchPrompt'>
          What do you want to know?
        </div>
        <div className="input-container">
            <form onSubmit={handleSubmit}>
              <input type="text" id="inputBox" placeholder="Enter your question here"  onChange={handleInputChange} />
              <button id="submitButton" disabled={isSubmitted}><IoSendOutline /></button>
            </form>
            {data && (
            <div>
                <span style={{"whiteSpace": "pre-line"}}>{data.llm_result}</span>
                { data.found_documents && (
                  <div className="approvalsrequested">
                      <div style={{marginBottom: 20}}><strong>Note</strong> While this is the answer we got, we found documents which you can request access to in order to refine this answer.</div>
                      {approvalsRequestComplete && (
                        <div>Requests have been made for these documents.</div>
                      )}
                      {!approvalsRequestComplete && (
                      <Button disabled={approvalsRequested || approvalsRequestComplete} variant={approvalsRequestComplete ? "success" : "primary"} onClick={()=>requestApprovals()} >{approvalsRequestComplete ? "Requested": "Make Request"}</Button>
                      )}
                  </div>
                )}
                { data.found_approval_request && (
                  <div className="approvalsrequested">
                      <strong>Note</strong> While this is the answer we got, we also found documents that will help you answer this question. We made requests to the owners for access, which you can track <a onClick={()=>navigate("/approvals")} >here.</a>
                  </div>
                )}
                <ResultList data={data.vector_db_results} />
            </div>)}
            {isSubmitted && !graphData && (
              <MagnifyingGlass
                visible={true}
                height="80"
                width="80"
                ariaLabel="magnifying-glass-loading"
                wrapperStyle={{}}
                wrapperClass="magnifying-glass-wrapper"
                glassColor="#c0efff"
                color="#e15b64"
              />
            )
            }
            {
              graphData && (
                <div className="graph-container">
                  <AnimateGraph relationships={graphData} />
                </div>
              )
            }
            {
              errorText && (
                <div className="error-search">{errorText}</div>
              )
            }
        </div>
      </div>
    </div>
  );
}

export default SearchPage;
