import React, { useState } from 'react';
import './AdminPage.css';
import MirielHeader from './MirielHeader';
import MirielFetch from './MirielFetch';

import useRedirectIfNoToken from './UseRedirect';

function AdminPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [serverMessage, setServerMessage] = useState('')
  const token = localStorage.getItem('userToken');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [debugLog, setDebugLog] = useState([]);

  const fetcher = new MirielFetch();

  useRedirectIfNoToken();

  const getDebugLog = async (event) => {
    event.preventDefault();
    fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/log', "POST", 
      {level: 'DEBUG'})
    .then( (response) => {
      console.log("GetDebugLog", response.data);
      setDebugLog(response.data['miriel.log']);
    })
    .catch (error => {          
      console.error("get debug log failed", error);
      setDebugLog('Error Getting debug log:'+error);
    });
  };

  const checkLoginStatus = async () => {
      fetcher.miriel_fetch(process.env.REACT_APP_APP_HOSTNAME_URL + '/api/v1/check_loggedin', "GET", 
        undefined)
        .then( (response) => {
          console.log("Request successful", response.data);
          setIsLoggedIn(response.data.loggedin);
        })
        .catch (error => {
          setServerMessage('Error getting logged in:'+error);
        });
  };

  return (
    <div>
      <MirielHeader />
      <h1>{serverMessage}</h1>
      <div className="entry-container">
        <h2>Check User Status</h2>
        <button onClick={checkLoginStatus}>Check Login Status</button>
        <p>{isLoggedIn ? "Logged In" : "Not Logged In"}</p>
      </div>
     
      <div className="entry-container">
        <h2>Get Debug Log</h2>
        <button type="submit" onClick={getDebugLog}>Refresh Debug Log</button>
        <div style={{ width: '600px', height: '300px', overflowY: 'scroll' }}>
          {debugLog.map((value, index) => (
            <div key={index}>
              {`${value}`}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AdminPage;